import React from "react"

import { Container} from 'reactstrap' 
import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from '../components/slider-technology' 
import redSafety from '../assets/images/red-safety.png'
import worldIcon from '../assets/images/world.png'
import pointCross from '../assets/images/point-cross.png'
import techTruck from '../assets/images/techTruck.png'
import tech1 from '../assets/images/tech1.png'
import tech2 from '../assets/images/tech2.png'
import tech3 from '../assets/images/tech3.png'
import tech4 from '../assets/images/tech4.png'
import tech5 from '../assets/images/tech5.png'
import tech6 from '../assets/images/tech6.png'
import tech7 from '../assets/images/tech7.png'
import tech8 from '../assets/images/tech9.png'
import tech9 from '../assets/images/tech8.png'
  
export default () => (
  <Layout>
    <SEO title="Technology" />
    <Slider title="TECHNOLOGY"/>
	<div className="world-brand" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<img className="world-icon" src={worldIcon} alt="worldIcon"/>
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
				<h4 className="surpassing"><b>SURPASSING</b> ALL BOUNDARIES FROM <b>US</b> TO <b>YOU</b></h4> 
			  </div>
			</div>
		</Container>
	</div>
	<div className="safety-background" >
		<Container className="pt-4">
			<div className="row block">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
				<img  className="redSafety" src={redSafety} alt="red icon safety"/> 
				<h4 className="safetyH"><b>SAFETY</b> AND<br/>  <b>PROTECTION</b></h4> 
				<p className="aboutP">{'On all our loads'}</p>  
				<img className="techTruckAbsolute" src={techTruck} />  
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 safetyblock">
				<div className="row">
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 techRelative">
						<h4 className="grayH marginSafety">{' '}</h4> 
						<p className="grayText">Gutierrez Trucking is a company that closely <b>monitors the security</b>  in the supply chain and is
												<b> constantly securing</b>  all the cargo. 
						</p>
						<div className="row techAbsolute">
							<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
								<div className="row cert-white"> 
									<img  src={tech1} alt="service 1"/>
									<h4 className="techSub"><b>Monitoring</b> and<br/> <b>24/7</b> Follow up</h4>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
								<div className="row cert-white"> 
									<img  src={tech2} alt="service 2"/> 
									<h4 className="techSub">Real Time <b>Geolocation</b></h4>
								</div>
							</div> 
							<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
								<div className="row cert-white"> 
									<img  src={tech3} alt="service 4"/> 
									<h4 className="techSub"><b>Direct Communication</b> with your driver (cellphone)</h4>
								</div>
							</div> 
							<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
								<div className="row cert-white"> 
									<img  src={tech4} alt="service 3"/> 
									<h4 className="techSub"><b>App</b> for live communication</h4>
								</div>
							</div> 
							<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
								<div className="row cert-white"> 
									<img  src={tech5} alt="service 4"/>
									<h4 className="techSub"><b>Panic button</b> for engine stop</h4>
								</div> 
							</div> 
							<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
								<div className="row cert-white"> 
									<img  src={tech6} alt="service 6"/> 
									<h4 className="techSub"><b>Geofenses</b> along the route</h4>
								</div>
							</div> 
							<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
								<div className="row cert-white"> 
									<img  src={tech7} alt="service 3"/> 
									<h4 className="techSub"><b>Email</b> follow up</h4>
								</div>
							</div> 
							<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
								<div className="row cert-white"> 
									<img  src={tech8} alt="service 4"/>
									<h4 className="techSub"><b>Live tracking</b></h4>
								</div> 
							</div> 
							<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
								<div className="row cert-white"> 
									<img  src={tech9} alt="service 6"/> 
									<h4 className="techSub">Electronic data interchange (<b>EDI</b>)</h4>
								</div>
							</div> 
						</div>
					</div> 
				</div>
			  </div>
			</div>
		</Container>
	</div>
	<div className="red-tech-background" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 techRelative2">
				<img className="techAbsolute2" src={pointCross} />  
			  </div> 
			</div> 
		</Container>
	</div>  
  </Layout>
)
